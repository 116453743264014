<template>
  <router-view></router-view>
    <!-- <div id="app">
    </div> -->
</template>

<script>
export default {
    name: 'app',

}
</script>

<style></style>
