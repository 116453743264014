
export const repeatTextWatermark = (canvas, text) => {
    const ctx = canvas.getContext('2d');
    ctx.font = '15px Vedana';
    ctx.fillStyle = 'rgba(112, 112, 112, 0.2)';
    ctx.textAlign = 'center'
    ctx.textBaseline = 'Middle'

    const spacingX = 150; // 水印在 X 轴上的间距
    const spacingY = 80; // 水印在 Y 轴上的间距
    const rotation = -20; // 水印旋转角度

    for (let x = -30; x < 10000; x += spacingX) {
        for (let y = 0; y < 10000; y += spacingY) {
            ctx.save();
            ctx.translate(x, y);
            ctx.rotate(rotation * Math.PI / 180);
            ctx.fillText(text, 0, 0);
            ctx.restore();
        }
    }
}
