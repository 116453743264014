<template>
    <div class="new-chat-container">
        <el-form label-width="0px" :model="params" class="login_form" @keyup.enter.native="submit">
            <el-form-item class="new-chat-item" prop="title">
                <div class="new-chat-item-container">
                    <span class="new-chat-item-label">标题</span>
                    <el-tooltip class="item" effect="dark" :content="titleTip" placement="top-start">
                        <i class="el-icon-question new-chat-item-icon" style="margin-left: 10px"></i>
                    </el-tooltip>
                    <el-input :disabled="showDetail" class="new-chat-item-select" v-model="params.title"
                        placeholder=""></el-input>
                </div>
            </el-form-item>
            <el-form-item class="new-chat-item" prop="model">
                <div class="new-chat-item-container">
                    <span class="new-chat-item-label" style="width: 77px;">模型</span>
                    <el-tooltip class="item" effect="dark" :content="modelTip" placement="top-start">
                        <i class="el-icon-question new-chat-item-icon" style="margin-left: 0px"></i>
                    </el-tooltip>
                    <el-select :disabled="showDetail" class="new-chat-item-select" v-model="params.model" placeholder="">
                        <el-option v-for="(item, index) in models" :key="index" :label="item.displayName" :value="item.name">
                        </el-option>
                    </el-select>
                </div>
            </el-form-item>
            <el-form-item class="new-chat-item" prop="prompt">
                <div class="new-chat-item-container">
                    <span class="new-chat-item-label">GPT设定</span>
                    <el-tooltip class="item" effect="dark" :content="backgroundTip" placement="top-start">
                        <i class="el-icon-question new-chat-item-icon" style="margin-left: 10px"></i>
                    </el-tooltip>
                    <el-input class="new-chat-item-textarea" :disabled="showDetail" v-model="params.prompt" placeholder=""
                        type="textarea" :autosize="{ minRows: 2, maxRows: 4 }"></el-input>
                </div>
            </el-form-item>
            <el-button v-if="!showDetail" class="new-chat-submit-button" type="primary" plain @click="submit">创建</el-button>
        </el-form>
    </div>
</template>

<script>
import { _listModel } from "@/services/chatgpt/chatgpt";

export default {
    name: "NewChat",
    props: {
        params: {
            type: Object,
            default() {
                return {
                    title: "",
                    model: "",
                    prompt: "",
                };
            },
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.init();
    },
    data() {
        return {
            models: [],
            titleTip: "聊天的标题",
            modelTip: "选择使用GPT模型",
            backgroundTip: "设定当前对话的整体背景, 有助于GPT更好的回答你的问题。例如: 你是一个科学家。",
            // params: {
            //     title: '',
            //     model: '',
            //     prompt: '',
            // }
        };
    },
    methods: {
        init() {
            this.getModels();
        },
        async getModels() {
            let res = await _listModel();
            console.log(res);

            this.models = res.result[0];

            console.log(this.models);
        },
        submit() {
            if (this.params.title == "") {
                this.$notify({
                    title: "警告",
                    message: "标题不能为空",
                    type: "warning",
                    duration: 3000,
                });
                return;
            }

            if (this.params.model == "") {
                this.$notify({
                    title: "警告",
                    message: "请选择模型",
                    type: "warning",
                    duration: 3000,
                });
                return;
            }

            // if (this.params.prompt == "") {
            //     this.$notify({
            //         title: "警告",
            //         message: "请输入背景提示语",
            //         type: "warning",
            //         duration: 3000,
            //     });
            //     return;
            // }

            this.$emit("submit", this.params);
        },
    },
};
</script>

<style lang="less">
.new-chat-container {
    display: flex;
    flex-direction: column;

    .new-chat-item {
        display: flex;
        flex-direction: row;
    }

    .new-chat-item-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .new-chat-item-label {
            width: 100px;
        }

        .new-chat-item-icon {
            margin-right: 20px;
            margin-left: -5px;
        }

        .new-chat-item-select input.el-input__inner {
            border-radius: 12px;
            width: 200px;
        }

        .new-chat-item-textarea .el-textarea__inner {
            width: 200px;
            border-radius: 12px;
        }
    }

    .new-chat-submit-button {
        align-self: center;
        width: 50%;
        transform: translate(50%, 0);
    }
}
</style>
