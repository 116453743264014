<template>
    <div class="pay-page">
        <el-menu :default-active="activeIndex" class="el-menu" mode="horizontal" @select="handleSelect">
            <el-menu-item index="1">充值套餐</el-menu-item>
            <el-menu-item index="2">订单系统</el-menu-item>
        </el-menu>

        <div v-if="activeIndex == 1" class="plan-list">
            <div v-show="showPlan" v-for="(item, index) in plans">
                <div :class="[selectedIndex == index ? 'pay-item-selected' : 'pay-item']" @click="selected(index)">
                    <div class="limited-time-offer">
                        <img v-show="item.planType !== 'Monthly-Subscription'" class="tag" src="../assets/images/limited-time-offer.png"/>
                        <img v-show="item.planType == 'Monthly-Subscription' && index == 3" class="tag" src="../assets/images/4z.png"/>
                        <img v-show="item.planType == 'Monthly-Subscription' && index == 4" class="tag" src="../assets/images/3z.png"/>
                        <img v-show="item.planType == 'Monthly-Subscription' && index == 5" class="tag" src="../assets/images/48z.png"/>
                    </div>
                    <div v-show="item.planType == 'Monthly-Subscription'" class="monthly-tip">
                        <span class="tag">包月套餐</span>
                    </div>
                    <div class="pay-item-title">
                        <span class="primary">{{ item.title }}</span>
                        <span class="secondary">{{ item.planTypeDisplayName }}</span>
                    </div>

                    <div class="pay-item-price">
                        <span v-show="item.planType == 'Monthly-Subscription'" class="price-decoration">￥{{
            item.priceDecoration }}</span>
                        <span class="price">￥{{ item.price }}</span>
                        <span v-show="item.planType == 'Pay-As-You-Go'" class="coins-decoration">{{
            item.coinsDecorationContent }}</span>
                        <span class="coins">{{ item.coinsContent }}</span>
                    </div>
                    <div class="pay-item-select-btn">
                        <span class="select">{{ item.planLevel > monthlyInfo.planLevel ? '升级' : '选择' }}</span>
                    </div>
                    <div class="pay-item-content">
                        <div class="pay-item-content-item" v-for="content in item.contents">
                            <img class="check" src="../assets/images/check_black.png" />
                            <span class="text">{{ content }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <el-dialog top="1vh" :modal="false" title="套餐升级" custom-class="plan-qrcode-container"
                :visible.sync="showUpgradeOrderTips" @close="handleUpgradeOrderTipsClose" :close-on-press-escape="false"
                :close-on-click-modal="false">
                <div class="pay-result" v-show="showUpgradeOrderTips">
                    套餐升级需要补充差价: ￥{{ diffPrice }}
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="handleUpgradeOrderTipsClose">取 消</el-button>
                    <el-button type="primary" @click="handleUpgradeOrderConfirm">确定</el-button>
                </div>
            </el-dialog>

            <el-dialog top="1vh" :modal="false" title="请使用微信扫码支付" custom-class="plan-qrcode-container"
                :visible.sync="showPayCodeImg" @close="handlePayCodeImgClose" :close-on-press-escape="false"
                :close-on-click-modal="false">
                <div class="pay-qrcode-container" v-show="showPayCodeImg">
                    <img class="pay-qrcode" :src="payCodeImg" alt="" />
                    <div class="pay-text">
                        <img class="pay-text-img" src="../assets/images/wechat_pay.png" alt="" />
                    </div>
                </div>
                <div slot="footer" class="dialog-footer">
                </div>
            </el-dialog>

            <el-dialog top="1vh" :modal="false" title="微信扫码支付结果" custom-class="plan-qrcode-container"
                :visible.sync="showPayResult" @close="handlePayResultClose" :close-on-press-escape="false"
                :close-on-click-modal="false">
                <div class="pay-result" v-show="showPayResult">
                    {{ payResult }}
                </div>
                <div slot="footer" class="pay-result-dialog-footer">
                </div>
            </el-dialog>
        </div>

        <div v-if="activeIndex == 2" class="order-list">
            <div v-for="item in orders" class="order-list-item">
                <div class="order-item-title">创建时间: {{ item.createdAt }}</div>
                <div class="order-item-body">
                    <span>金额: {{ item.cost }}</span>
                    <span>状态: {{ item.status }}</span>
                </div>
            </div>
            <el-pagination class="" small :page-size="13" layout="prev, pager, next, jumper, ->, total" :total="tablePagination.total"
                    @current-change="handlePageChange">
                </el-pagination>
        </div>
    </div>
</template>

<script>
import { _getChatGPTPlans, _getOrders } from '@/services/chatgpt/chatgpt'
import { formatDateTime } from '@/utils/formatTime'

export default {
    name: 'Pay',
    props: {
        showPlan: {
            type: Boolean,
            default: true
        },
        showPayCodeImg: {
            type: Boolean,
            default: false
        },
        payCodeImg: {
            type: String,
            default: ''
        },
        showPayResult: {
            type: Boolean,
            default: false
        },
        payResult: {
            type: String,
            default: ''
        },
        showUpgradeOrderTips: {
            type: Boolean,
            default: false
        },
        diffPrice: {
            type: String,
            default: '0'
        },
        monthlyInfo: {
            type: Object,
            default: () => {
                return {
                    startTime: '',
                    endTime: '',
                    planLevel: '',
                    planDisplayName: '',
                    supportModels: [],
                }
            }
        },
    },
    data() {
        return {
            activeIndex: "1",
            selectedIndex: 0,
            showPayContainer: true,
            showOrders: false,
            plans: [],
            tablePagination: { pageSize: 5 },
            orders: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.getChatGPTPlans();
        },
        handleSelect(e) {
            console.log('handleSelect', e)
            this.activeIndex = e;

            if (this.activeIndex == "2") {
                this.getOrders();
            }
        },
        selected(e) {
            // TODO: 当包月套餐功能实现之后再删除这段代码
            // const item = this.plans[e]
            // if (item.planType == 'Monthly-Subscription') {
            //     return
            // }

            this.selectedIndex = e
            this.$emit('selectedPlan', this.plans[this.selectedIndex])
        },
        async getChatGPTPlans() {
            let res = await _getChatGPTPlans();
            if (res.success) {
                this.plans = res.result[0].plans
                console.log('this.plans', this.plans)
                this.selectedIndex = this.plans.findIndex(x => x.selected)
                this.$emit('selectedPlan', this.plans[this.selectedIndex])
            }

        },

        async getOrders() {
            const { pageSize, pageNo } = this.tablePagination;
            const params = {
                pageNo,
                pageSize
            }
            console.log(params)
            let res = await _getOrders(params)
            console.log('getOrders', res)
            if (res.success) {
                this.orders = res.result[0].orders
                this.tablePagination = res.pagination
            } else {
                this.$notify.error({
                    title: '错误',
                    message: res.message,
                    duration: 3000,
                });
            }
        },

        handleMenuClick(menu) {
            console.log(menu)
            switch (menu) {
                case 1:
                    this.$emit('selectedMenu', 'plan')
                    this.showPayContainer = true
                    this.showOrders = false
                    break
                case 2:
                    this.getOrders();
                    this.$emit('selectedMenu', 'order')
                    this.showPayContainer = false
                    this.showOrders = true
                    break
            }
        },

        handlePageChange(page) {
            console.log('handlePageChange', page)
            this.tablePagination.pageNo = page
            this.getOrders()
        },

        handlePayCodeImgClose() {
            this.$emit('closePayCodeImg', true)
        },

        handlePayResultClose() {
            this.$emit('closePayResult', true)
        },

        handleUpgradeOrderTipsClose() {
            this.$emit('closeUpgradeOrderTips', true)
        },

        handleUpgradeOrderConfirm() {
            this.$emit('confirmUpgradeOrder', true)
        },

        formatCreatedAt(row, column) {
            let newDate = new Date(Date.parse(row.createdAt))

            return formatDateTime(newDate)
        },

        formatPayTpye(row, column) {
            switch (row.payType) {
                case 'WXPAY':
                    return "微信支付"
                case 'ALIPAY':
                    return "支付宝支付"
            }
        },
    },
}
</script>

<style lang="less" scoped>
@fendi-yellow: rgba(244, 188, 78);
@fendi-yellow-opacity: rgba(244, 188, 78, 0.5);
@fendi-black: rgba(21, 22, 20);

.pay-item-content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.pay-item-content::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: #999999b0;
    -webkit-border-radius: 5px;
}

.limited-time-offer {
    position: absolute;
    right: 20px;
    top: 30%;
    transform: rotate(20deg);
    // width: 100px;
    // height: 100px;

    .tag {
        width: 65px;
    }
}

.pay-page {
    width: 100vw;
    // background-color: #ef7511;
    margin-top: -30px;
    width: 1000px;
    display: flex;
    flex-direction: column;

    .el-menu {
        width: 80vw;
    }

    .plan-list {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;

        .pay-item {
            width: 80vw;
            height: 360px;
            border-radius: 5px;
            background-color: rgb(255, 255, 255);
            margin-bottom: 10px;
            position: relative;

            display: flex;
            flex-direction: column;
            border-style: solid;
            border-width: 1.8px;
            border-color: rgb(218, 218, 218);

            .monthly-tip {
                position: absolute;
                color: @fendi-black;
                font-family: MesloLGS_NF_Bold;
                font-size: 12px;
                font-weight: bold;
                width: 100px;
                height: 100px;
                right: 0;
                border-radius: 10px;
                line-height: 20px;
                text-align: center;
                overflow: hidden;

                .tag {
                    text-align: center; // 文本居中 
                    position: absolute; // 绝对定位
                    background-color: grey; // 背景色
                    color: #fff;
                    // 以下属性会影响斜边标签的显示
                    width: 80%;
                    height: 18px;
                    line-height: 18px;
                    top: 10px;
                    left: 40%;
                    transform: rotate(45deg);
                    border: 1px solid #000;
                }
            }

            .pay-item-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: rgba(167, 164, 130, 1);
                font-family: MesloLGS_NF_Regular;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                width: 100%;
                height: 80px;
                background-color: rgba(237, 236, 228, 1);

                .primary {
                    font-family: MesloLGS_NF_Bold;
                    font-size: 18px;
                    line-height: 40px;
                }

                .secondary {
                    font-size: 12px;
                }
            }

            .pay-item-icon {
                width: 100%;
            }

            .pay-item-price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 120px;
                font-family: MesloLGS_NF_Bold;

                .price {
                    font-size: 18px;
                    line-height: 40px;
                }

                .price-decoration {
                    font-size: 14px;
                    line-height: 20px;
                    text-decoration: line-through;
                    color: #6a656599;
                }

                .coins {
                    font-size: 16px;
                }

                .coins-decoration {
                    font-size: 12px;
                    text-decoration: line-through;
                    color: #6a656599;
                }
            }

            .pay-item-select-btn {
                align-self: center;
                text-align: center;
                height: 34px;
                line-height: 34px;
                width: 80%;
                font-family: MesloLGS_NF_Bold;
                color: rgb(170, 167, 134);
                background-color: rgba(237, 236, 228, 1);

                .select {
                    font-size: 16px;
                }
            }

            .pay-item-content {
                height: 126px;
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                overflow-y: auto;

                .pay-item-content-item {
                    width: 100%;
                    margin-top: 5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: MesloLGS_NF_Bold;

                    .check {
                        width: 14px;
                        height: 14px;
                        margin-right: 10px;
                        margin-left: 12%;
                    }

                    .text {
                        color: black;
                        font-size: 14px;
                    }
                }
            }

        }

        .pay-item-selected {
            width: 80vw;
            height: 360px;
            border-radius: 5px;
            background-color: rgb(255, 255, 255);
            margin-bottom: 10px;
            position: relative;

            display: flex;
            flex-direction: column;
            border-style: solid;
            border-width: 1.8+0.2px;
            // border-color: rgb(167, 164, 130);
            border-color: @fendi-yellow;

            .monthly-tip {
                position: absolute;
                color: @fendi-black;
                font-family: MesloLGS_NF_Bold;
                font-size: 12px;
                font-weight: bold;
                width: 100px;
                height: 100px;
                right: 0;
                border-radius: 10px;
                line-height: 20px;
                text-align: center;
                overflow: hidden;

                .tag {
                    text-align: center; // 文本居中 
                    position: absolute; // 绝对定位
                    background-color: rgba(208, 29, 29, 0.701); // 背景色
                    color: #fff;
                    // 以下属性会影响斜边标签的显示
                    width: 80%;
                    height: 18px;
                    line-height: 18px;
                    top: 10px;
                    left: 40%;
                    transform: rotate(45deg);
                    border: 1px solid #000;
                }
            }

            .pay-item-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: @fendi-black;
                font-family: MesloLGS_NF_Regular;

                width: 100%;
                height: 80px;
                // background-color: rgba(167, 164, 130, 1);
                background-color: @fendi-yellow;

                .primary {
                    font-family: MesloLGS_NF_Bold;
                    font-size: 18px;
                    line-height: 40px;
                }

                .secondary {
                    font-size: 12px;
                }
            }

            .pay-item-icon {
                width: 100%;
            }

            .pay-item-price {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 120px;
                font-family: MesloLGS_NF_Bold;

                .price {
                    font-size: 18px;
                    line-height: 40px;
                }

                .price-decoration {
                    font-size: 14px;
                    line-height: 20px;
                    text-decoration: line-through;
                    color: #6a656599;
                }

                .coins {
                    font-size: 16px;
                }

                .coins-decoration {
                    font-size: 12px;
                    text-decoration: line-through;
                    color: #6a656599;
                }
            }

            .pay-item-select-btn {
                align-self: center;
                text-align: center;
                height: 34px;
                line-height: 34px;
                width: 80%;
                font-family: MesloLGS_NF_Bold;
                color: @fendi-black;
                background-color: @fendi-yellow;

                .select {
                    font-size: 16px;
                }
            }

            .pay-item-content {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                width: 100%;
                height: 126px;
                overflow-y: auto;
                align-items: center;

                .pay-item-content-item {
                    width: 100%;
                    margin-top: 5px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    font-family: MesloLGS_NF_Bold;

                    .check {
                        width: 14px;
                        height: 14px;
                        margin-right: 10px;
                        margin-left: 12%;
                    }

                    .text {
                        color: black;
                        font-size: 14px;
                    }
                }
            }
        }

        .plan-1 {
            width: 280px;
            height: 210px;
            border-radius: 24px;
            background-color: rgba(225, 225, 225, 0.61);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 40px;
            position: relative;

            .hot-icon {
                position: absolute;
                left: 220px;
                top: 10px;

                .hot-icon-img {
                    width: 50px;
                    height: 50px;
                }
            }

            .plan-container {
                width: 230px;
                height: 170px;
                border-radius: 24px;
                display: flex;
                flex-direction: column;

                .plan-container-header {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    width: 230px;
                    height: 50%;
                    background-color: #646464;
                    display: flex;
                    flex-direction: column;

                    .header-dot {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #fff;
                    }

                    .header-dot-selected {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #ef7511;
                    }

                    .header-text {
                        float: right;
                        width: 230px;
                        -webkit-text-stroke: 1px rgba(83, 79, 77, 0.37);
                        overflow-wrap: break-word;
                        color: rgba(255, 255, 255, 0.866);
                        font-size: 30px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        margin: 35px, auto;
                    }
                }

                .plan-container-footer {
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;
                    width: 230px;
                    height: 50%;
                    background-color: #fff;

                    .price-text {
                        float: right;
                        width: 230px;
                        height: 40px;
                        -webkit-text-stroke: 1px rgba(83, 79, 77, 0.37);
                        overflow-wrap: break-word;
                        color: rgba(74, 70, 66, 0.866);
                        font-size: 20px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 40px;
                    }

                    .free-coins-text {
                        float: right;
                        width: 230px;
                        height: 40px;
                        overflow-wrap: break-word;
                        color: rgb(226, 109, 25);
                        font-size: 16px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 40px;
                    }
                }
            }

            .plan-container-selected {
                width: 250px;
                height: 190px;
                border-radius: 24px;
                display: flex;
                flex-direction: column;

                .plan-container-header {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    width: 250px;
                    height: 50%;
                    background-color: #646464;
                    display: flex;
                    flex-direction: column;

                    .header-dot {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #fff;
                    }

                    .header-dot-selected {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #ef7511;
                    }

                    .header-text {
                        float: right;
                        width: 250px;
                        -webkit-text-stroke: 1px rgba(83, 79, 77, 0.37);
                        overflow-wrap: break-word;
                        color: rgba(255, 255, 255, 0.866);
                        font-size: 30px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        margin: 35px, auto;
                    }
                }

                .plan-container-footer {
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;
                    width: 250px;
                    height: 50%;
                    background-color: #fff;

                    .price-text {
                        float: right;
                        width: 250px;
                        height: 40px;
                        -webkit-text-stroke: 1px rgba(83, 79, 77, 0.37);
                        overflow-wrap: break-word;
                        color: rgba(74, 70, 66, 0.866);
                        font-size: 20px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 40px;
                    }

                    .free-coins-text {
                        float: right;
                        width: 250px;
                        height: 40px;
                        overflow-wrap: break-word;
                        color: rgb(226, 109, 25);
                        font-size: 16px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 40px;
                    }
                }
            }
        }

        .plan-2 {
            width: 280px;
            height: 210px;
            border-radius: 24px;
            background-color: rgba(225, 225, 225, 0.61);
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;

            .hot-icon {
                position: absolute;
                left: 220px;
                top: 10px;

                .hot-icon-img {
                    width: 50px;
                    height: 50px;
                }
            }

            .plan-container {
                width: 230px;
                height: 170px;
                border-radius: 24px;
                display: flex;
                flex-direction: column;

                .plan-container-header {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    width: 230px;
                    height: 50%;
                    background-color: #414141;
                    display: flex;
                    flex-direction: column;

                    .header-dot {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #fff;
                    }

                    .header-dot-selected {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #ef7511;
                    }

                    .header-text {
                        float: right;
                        width: 230px;
                        -webkit-text-stroke: 1px rgba(83, 79, 77, 0.37);
                        overflow-wrap: break-word;
                        color: rgba(255, 255, 255, 0.866);
                        font-size: 30px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        margin: 35px, auto;
                    }
                }

                .plan-container-footer {
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;
                    width: 230px;
                    height: 50%;
                    background-color: #fff;

                    .price-text {
                        float: right;
                        width: 230px;
                        height: 40px;
                        -webkit-text-stroke: 1px rgba(83, 79, 77, 0.37);
                        overflow-wrap: break-word;
                        color: rgba(74, 70, 66, 0.866);
                        font-size: 20px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 40px;
                    }

                    .free-coins-text {
                        float: right;
                        width: 230px;
                        height: 40px;
                        overflow-wrap: break-word;
                        color: rgb(226, 109, 25);
                        font-size: 16px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 40px;
                    }
                }
            }

            .plan-container-selected {
                width: 250px;
                height: 190px;
                border-radius: 24px;
                display: flex;
                flex-direction: column;

                .plan-container-header {
                    border-top-left-radius: 24px;
                    border-top-right-radius: 24px;
                    width: 250px;
                    height: 50%;
                    background-color: #414141;
                    display: flex;
                    flex-direction: column;

                    .header-dot {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #fff;
                    }

                    .header-dot-selected {
                        margin-top: 10px;
                        margin-left: 10px;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #ef7511;
                    }

                    .header-text {
                        float: right;
                        width: 250px;
                        -webkit-text-stroke: 1px rgba(83, 79, 77, 0.37);
                        overflow-wrap: break-word;
                        color: rgba(255, 255, 255, 0.866);
                        font-size: 30px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        margin: 35px, auto;
                    }
                }

                .plan-container-footer {
                    border-bottom-left-radius: 24px;
                    border-bottom-right-radius: 24px;
                    width: 250px;
                    height: 50%;
                    background-color: #fff;

                    .price-text {
                        float: right;
                        width: 250px;
                        height: 40px;
                        -webkit-text-stroke: 1px rgba(83, 79, 77, 0.37);
                        overflow-wrap: break-word;
                        color: rgba(74, 70, 66, 0.866);
                        font-size: 20px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 40px;
                    }

                    .free-coins-text {
                        float: right;
                        width: 250px;
                        height: 40px;
                        overflow-wrap: break-word;
                        color: rgb(226, 109, 25);
                        font-size: 16px;
                        font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                        font-weight: bold;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 40px;
                    }
                }
            }
        }

        .pay-qrcode-container {
            border-style: solid;
            border-width: 0.1px;
            border-color: #505050a1;
            width: 350px;
            height: 500px;
            border-radius: 24px;
            background-color: rgba(83, 161, 72, 1);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .pay-qrcode {
                width: 226px;
                height: 226px;
                margin-top: 80px;
                border-radius: 5px;
            }

            .pay-text {
                width: 350px;
                border-bottom-left-radius: 24px;
                border-bottom-right-radius: 24px;
                height: 100px;
                background-color: white;

                .pay-text-img {
                    position: relative;
                    left: 50%;
                    transform: translate(-50%, 10%);
                    width: 226px;
                }
            }
        }

        .dialog-footer {
            height: 40px;
        }

        .pay-result-dialog-footer {
            height: 200px;
        }
    }

    .order-list {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        font-family: MesloLGS_NF_Bold;
        
        .order-list-item {
            display: flex;
            flex-direction: column;
            width: 80vw;
            height: 100px;
            border-bottom: 1px solid #D3D0D8;
            margin-top: 5px;
            border-radius: 5px;
            background-color: #ffffffc0;
            color: #000;

            .order-item-title {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                background-color: #b4b1b1b5;
                width: 80vw;
                font-size: 16px;
            }

            .order-item-body {
                margin-top: 5px;
                display: flex;
                flex-direction: column;
            }

        }
    }
}
</style>