// 鼠标移动滚动位置类
class Drag {
    constructor(vm) {
        this.dragWrap = vm;// 要挂载的容器
        this._dom = {};
        this._x = 0;
        this._y = 0;
        this._top = 0;
        this._left = 0;
        this.move = false;
        this.down = false;
        this.init.apply(this, arguments);
    }

    // 绑定事件
    init() {
        this.bindEvent();
    }

    // 给要素增加鼠标事件
    // mousedown 按下初始化
    // mousemove 移动
    // mouseup，mouseleave 松开，移出结束移动
    bindEvent() {
        let t = this;
        this.dragWrap.addEventListener('mousedown', (e) => {
            e && e.preventDefault();
            if (!t.move) {
                t.move = false;
                t.down = true;
                t._x = e.clientX;
                t._y = e.clientY;
                t._top = t.dragWrap.scrollTop;
                t._left = t.dragWrap.scrollLeft;
            }
        }, { passive: false });
        this.dragWrap.addEventListener('mouseup', (e) => {
            e && e.preventDefault();
            t.move = false;
            t.down = false;
        }, { passive: false });
        this.dragWrap.addEventListener('mouseleave', (e) => {
            e && e.preventDefault();
            t.move = false;
            t.down = false;
        }, { passive: false });
        this.dragWrap.addEventListener('mousemove', (e) => {
            if (t.down) {
                e && e.preventDefault();
                t.move = true;
                let x = t._x - e.clientX;
                let y = t._y - e.clientY;
                t.dragWrap.scrollLeft = t._left + x;
                t.dragWrap.scrollTop = t._top + y;
            }
        }, { passive: false });
    }
}

export default Drag;
