import { post, get, Delete, stream, put } from '../http';

// Register
export const _register = (param = {}) => {
    return post('/earthtail/api/v1/earthtails/users/register', param)
}

// Reset Password
export const _resetPassword = (param = {}) => {
    return post('/earthtail/api/v1/earthtails/users/password/reset', param)
}

// Login
export const _login = (param = {}) => {
    return post('/earthtail/api/v1/earthtails/users/login', param)
}

// Logout
export const _logout = (param = {}) => {
    return post('/earthtail/api/v1/earthtails/users/logout', param)
}

export const _getCaptchaId = (parma = {}) => {
    return get('/earthtail/api/v1/earthtails/infras/captchas/id', parma)
}

export const _reloadCaptcha = (parma = {}) => {
    return post(`/earthtail/api/v1/earthtails/infras/captchas/reload/${parma.uid}`, parma)
}

export const _submitCaptchaId = (param = {}) => {
    return post('/earthtail/api/v1/earthtails/infras/captchas/id', param)
}

export const _getSmsCode = (param = {}) => {
    return post('/earthtail/api/v1/earthtails/infras/sms/code', param)
}


// Infra
export const _getChatGPTPlans = (param = {}) => {
    return get('/earthtail/api/v1/earthtails/chatgpts/plans', param)
}

export const _getUserCoinsBalance = (param = {}) => {
    return get('/earthtail/api/v1/earthtails/chatgpts/coins', param)
}

export const _getJsapiConfig = (param = {}) => {
    return get(`/earthtail/api/v1/earthtails/infras/wechat/jsapi/config`, param)
}

// ChatGPT
export const _getChatRecords = (param = {}) => {
    return get('/earthtail/api/v1/earthtails/chatgpts/records', param)
}

export const _getChatConversations = (param = {}) => {
    return get('/earthtail/api/v1/earthtails/chatgpts/conversations', param)
}

export const _updateChatConversationsSortKey = (param = {}) => {
    return put('/earthtail/api/v1/earthtails/chatgpts/conversations/sort', param)
}

export const _updateChatConversationsInfo = (param = {}) => {
    return put('/earthtail/api/v1/earthtails/chatgpts/conversations/info', param)
}

export const _createChatConversation = (param = {}) => {
    return post('/earthtail/api/v1/earthtails/chatgpts/conversations', param)
}

export const _deleteChatConversation = (param = {}) => {
    return Delete(`/earthtail/api/v1/earthtails/chatgpts/conversations/${param.uid}`, param)
}

// ChatGPT Models
export const _listModel = (param = {}) => {
    return get('/earthtail/api/v1/earthtails/chatgpts/models', param)
}

// PAY
export const _pay = (param = {}) => {
    return post('/earthtail/api/v1/earthtails/pay', param)
}

export const _getOrders = (param = {}) => {
    return get('/earthtail/api/v1/earthtails/pay', param)
}

export const _getOrderStatus = (param = {}) => {
    return get(`/earthtail/api/v1/earthtails/pay/status/${param.id}`, param)
}

export const _preUpgradeOrder = (param = {}) => {
    return post(`/earthtail/api/v1/earthtails/pay/preUpgrade`, param)
}

export const _upgradeOrder = (param = {}) => {
    return post(`/earthtail/api/v1/earthtails/pay/upgrade`, param)
}

// User
export const _getUserInfo = (param = {}) => {
    return get('/earthtail/api/v1/earthtails/users/info', param)
}