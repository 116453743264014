<template>
    <div :id="chatgptDivId" class="chatgpt_container" ref="chatgptContainer">
        <div :class="[!mobileLogin ? 'chatgpt_container_inner': 'chatgpt_container_inner-mobile']">
            <div class="chatgpt_container_inner_title">
                <div class="image_wrapper">
                    <img class="logo" referrerpolicy="no-referrer" src="../assets/images/etai_logo.png" />
                </div>
                <span class="chatgpt_title">ETAI GPT</span>
            </div>
            <div class="chatgpt_content">
                <mavon-editor :value="content" :subfield="false" defaultOpen="preview" :editable="false"
                    :toolbarsFlag="false" previewBackground="#ffffff00" style="height: 100%"></mavon-editor>
            </div>
            <div class="icon-container">

                <div class="copy-icon" v-clipboard:copy="content" v-clipboard:success="onCopy">
                    <el-tooltip class="item" effect="light" content="复制" placement="top">
                        <i class="el-icon-copy-document" style="margin-left: 0px"></i>
                    </el-tooltip>
                </div>

                <!-- <div class="quanping-icon" @click="fullScreen">
                    <el-tooltip class="item" effect="light" content="全屏" placement="top">
                        <i class="el-icon-full-screen"></i>
                    </el-tooltip>
                </div> -->

                <div class="screenshot-icon" @click="screenshotAndDownload">
                    <el-tooltip class="item" effect="light" content="截图" placement="top">
                        <i class="el-icon-camera-solid screenshot-icon-img"></i>
                    </el-tooltip>
                </div>
            </div>

        </div>

        <el-dialog custom-class="chatgpt-dialog" title="ETAI GPT" :visible.sync="showContentDialog" :modal="false">
            <div class="copy-icon" v-clipboard:copy="content" v-clipboard:success="onCopy">
                <img class="copy-icon-img" src="../assets/images/copy.png" />
            </div>
            <mavon-editor :value="content" :subfield="false" defaultOpen="preview" :editable="false" :toolbarsFlag="false"
                previewBackground="#ffffff" style="height: 100%"></mavon-editor>
            <span slot="footer" class="dialog-footer">
                <el-button v-clipboard:copy="content" v-clipboard:success="onCopy"
                    @click="showContentDialog = false">复制</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>

import html2canvas from 'html2canvas';
import { randomString } from '@/utils/formatTime'
import { repeatTextWatermark } from '@/utils/watermark';

export default {
    name: 'ChatGPT',
    props: {
        content: String,
        mobileLogin: Boolean
    },
    data() {
        return {
            showContentDialog: false,
            chatgptDivId: randomString(26),
        }
    },
    methods: {
        addCopyButtons() {
            console.log('addCopyButtons')
            let that = this;
            console.log('addCopyButtons 22222')

            if (this.$refs.chatgptContainer) {
                const codeBlocks = this.$refs.chatgptContainer.querySelectorAll('pre div'); // 找到所有代码块
                console.log('pre code codeBlocks', codeBlocks)
    
                codeBlocks.forEach(block => {
                    console.log('pre code block', block)
                    const button = document.createElement("button");
                    button.className = 'code-copy-btn';
                    button.textContent = "复制";
                    button.onclick = function () {
                        const code = block.querySelector('code').innerText;
                        navigator.clipboard.writeText(code).then(() => {
                            that.$notify({
                                type: 'success',
                                message: '复制成功',
                                duration: 3000,
                            });
                        }, (err) => {
                            that.$notify.error({
                                title: '错误',
                                message: '复制失败',
                                duration: 3000,
                            });
                        });
                    };
    
                    block.parentNode.insertBefore(button, block);
                    block.parentNode.style.position = 'relative';
                });
            }

        },
        onCopy(e) {
            this.$notify({
                type: 'success',
                message: '复制成功',
                duration: 3000,
            });
            console.log('You just copied: ' + e.text)
        },
        fullScreen() {
            this.showContentDialog = true
        },
        screenshotAndDownload() {
            html2canvas(document.getElementById(`${this.chatgptDivId}`), {
                windowHeight: 10000
            }).then(canvas => {
                repeatTextWatermark(canvas, 'EARTHTAIL AI')

                const imgUrl = canvas.toDataURL('image/png')
                const link = document.createElement('a')
                link.download = 'screenshot.png'
                link.href = imgUrl

                link.click();
            })
        }
    },
    mounted() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.addCopyButtons();
            }, 100)
        });
    }
}
</script>

<style lang="less">
.code-copy-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0 10px;
    background: #FFFFFF;
    border: 1px solid #5592E5;
    border-radius: 2px;
    font-size: 14px;
    color: #5592E5;
    cursor: pointer;

    &:hover {
        background-color: #ecf5ff;
    }
}
</style>

<style lang="less" scoped>
.chatgpt_container {
    height: auto;
    border-radius: 17px;
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    align-items: center;

    .chatgpt_container_inner {
        margin-left: 30px;
        width: 98%;
        height: auto;

        background: #DDDDDD;
        box-shadow: 8px 6px 23px 0px rgba(174, 174, 174, 0.7);
        border-radius: 17px;
        display: flex;
        flex-direction: column;

        .chatgpt_container_inner_title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .image_wrapper {
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 50%;
                height: 39px;
                width: 39px;
                margin: 22px 0px 0px 62px;

                .logo {
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                }
            }

            .chatgpt_title {
                width: 100px;
                height: 20px;
                margin: 22px 0px 0px 22px;
                font-size: 20px;
                font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                color: #000000;
                line-height: 20px;
                -webkit-text-stroke: 1px #000000;
            }
        }

        .chatgpt_content {
            overflow: hidden;
            overflow-y: scroll;
            width: calc(100% - 200px);
            margin: 8px 0px 0px 117px;
            font-size: 18px;
            font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
            font-weight: 400;
            color: #6e6e6e94;
            line-height: 28px;
        }


        .icon-container {
            display: flex;
            flex-direction: row;

            .copy-icon {
                margin-top: 20px;
                margin-left: 80px;
                cursor: pointer;
                .copy-icon-img {
                    width: 20px;
                    height: 20px;
                }
            }

            .quanping-icon {
                margin-top: 20px;
                margin-left: 20px;
                cursor: pointer;

                .quanping-icon-img {
                    width: 20px;
                    height: 20px;
                }
            }

            .screenshot-icon {
                margin-top: 20px;
                margin-left: 20px;
                cursor: pointer;
                .screenshot-icon-img {
                    width: 20px;
                    height: 20px;
                }
            }
        }

    }

    .chatgpt_container_inner-mobile {
        width: 95vw;
        height: auto;

        background: #DDDDDD;
        box-shadow: 8px 6px 23px 0px rgba(174, 174, 174, 0.7);
        border-radius: 17px;
        display: flex;
        flex-direction: column;

        .chatgpt_container_inner_title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .image_wrapper {
                background-color: rgba(255, 255, 255, 0.8);
                border-radius: 50%;
                height: 39px;
                width: 39px;
                margin: 22px 0px 0px 20px;

                .logo {
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                }
            }

            .chatgpt_title {
                width: 100px;
                height: 20px;
                margin: 22px 0px 0px 22px;
                font-size: 20px;
                font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
                color: #000000;
                line-height: 20px;
                -webkit-text-stroke: 1px #000000;
            }
        }

        .chatgpt_content {
            overflow: hidden;
            overflow-y: scroll;
            width: 98%;
            margin: 8px 0px 0px 5px;
            font-size: 18px;
            font-family: Helvetica, 'Microsoft YaHei', Arial, sans-serif;
            font-weight: 400;
            color: #6e6e6e94;
            line-height: 28px;
        }


        .icon-container {
            display: flex;
            flex-direction: row;

            .copy-icon {
                margin-bottom: 10px;
                margin-left: 20px;
                cursor: pointer;
                .copy-icon-img {
                    width: 20px;
                    height: 20px;
                }
            }

            .quanping-icon {
                margin-bottom: 10px;
                margin-left: 20px;
                cursor: pointer;

                .quanping-icon-img {
                    width: 20px;
                    height: 20px;
                }
            }

            .screenshot-icon {
                margin-bottom: 10px;
                margin-left: 20px;
                cursor: pointer;
                .screenshot-icon-img {
                    width: 20px;
                    height: 20px;
                }
            }
        }

    }
}

.chatgpt_content::-webkit-scrollbar {
    display: none;
}

::v-deep .markdown-body {
    background-color: #ffffff00;
}
</style>