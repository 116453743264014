import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  Dialog,
  Message,
  Select,
  Option,
  Tooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Notification,
  Loading,
  Menu,
  MenuItem,
  Table,
  TableColumn,
  Pagination,
  Popover,
  Switch,
} from "element-ui";

import VueClipboard from "vue-clipboard2";
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';

Message.install = function (Vue, options) {
  Vue.prototype.$message = Message;
};

Notification.install = function (Vue, options) {
  Vue.prototype.$notify = Notification
}

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Dialog);
Vue.use(Message);
Vue.use(Select);
Vue.use(Option);
Vue.use(Tooltip);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Notification);
Vue.use(Loading);
Vue.use(Menu);
Vue.use(MenuItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(VueClipboard);
Vue.use(Popover);
Vue.use(Switch);

Vue.use(mavonEditor);

