import axios from 'axios';
import { MessageBox } from 'element-ui';
import Vue from 'vue';
import md5 from 'blueimp-md5';

const stringify = o => {
    try {
        if (!_.isEmpty(o)) {
            return JSON.stringify(o);
        }
        return '';
    } catch (e) {
        return '';
    }
};

const initPagination = o => {
    try {
        if (o && o.success && o.result[0] && o.result[0].pageNo) {
            const pager = o.result[0];
            o.pagination = {
                pageNo: pager.pageNo,
                pageSize: pager.pageSize || 10,
                total: pager.total
            };
        }
    } catch (error) {
        console.log('initPagination', error);
    }
};

/**
 * 请求配置
 * @see https://github.com/mzabriskie/axios
 */

axios.defaults.withCredentials = true

const instance = axios.create({
    timeout: 20000, // 请求超时时间
    withCredentials: true, // 跨域
});

instance.interceptors.request.use(
    config => {
        let params = Object.assign(config.params || {}, {
            _: Date.now()
        });

        console.log('request config', config)

        let cookie = document.cookie
        console.log('request cookie', cookie)
        // config.headers['earthtail_token'] = cookie

        // 防止重复提交token
        // config.headers.common['X-Anti-Request-Token'] = md5(stringify(config.body || config.params));

        // config.headers.common['X-Requested-With'] = 'XMLHttpRequest';

        // if (!config.params) {
        //     // post 时强行给url上加上参数
        //     config.params = params;
        // }

        return config;
    },
    error => {
        Promise.reject(error);
    }
);

instance.interceptors.response.use(
    response => {
        initPagination(response.data); // 保证分页参数与分页组件统一
        return response.data;
    },
    error => {
        console.log('global error', error)
        let data = error.response.data;
        if (data.code == 1000403) {
            console.log('window.location.href', window.location.href)

            var url = window.location.href
            var arrUrl = url.split('//')

            var start = arrUrl[1].indexOf('/')
            var relUrl = arrUrl[1].substring(start)

            if (relUrl.indexOf('?') != -1) {
                relUrl = relUrl.split('?')[0]
            }

            console.log(relUrl)

            if (relUrl !== '/login') {
                MessageBox.confirm('登录失效，请重新登录', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                    center: true,
                    showCancelButton: false
                }).then(() => {
                    if (window.location.href !== '/login') {
                        window.location.href = '/login'
                    }
                })
            }

        }

        return error.response.data;
    }
);

export default instance;
